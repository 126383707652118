@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;


@font-face {
  font-family: "Poppins-Regular";
  src: url("./Admin/Components/Font/Poppins/Poppins-Regular.ttf")
    format("truetype");
}
@font-face {
  font-family: "Poppins-Light";
  src: url("./Admin/Components/Font/Poppins/Poppins-Light.ttf")
    format("truetype");
}
@font-face {
  font-family: "Poppins-ExtraLight";
  src: url("./Admin/Components/Font/Poppins/Poppins-ExtraLight.ttf")
    format("truetype");
}
@font-face {
  font-family: "Poppins-Medium";
  src: url("./Admin/Components/Font/Poppins/Poppins-Medium.ttf")
    format("truetype");
}
@font-face {
  font-family: "Poppins-SemiBold";
  src: url("./Admin/Components/Font/Poppins/Poppins-SemiBold.ttf")
    format("truetype");
}

/* @layer base {
	html {
		font-family: 'Inter', sans-serif;
		line-height: 1.3;
	}
	table { }
	th,td {
		@apply py-3 px-2.5 text-sm text-left border-y border-gray-200;
	}
	thead {
		@apply bg-neutral-100;
	}
	th {
		@apply font-semibold;
	}
} */

body {
  background-color: #fafafb;
}
/* fadein */
.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@-webkit-keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-20px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
}

.spinner {
  width: 11.2px;
  height: 11.2px;
  border-radius: 11.2px;
  box-shadow: 28px 0px 0 0 rgba(0, 231, 255, 0.2),
    22.7px 16.5px 0 0 rgba(0, 231, 255, 0.4),
    8.68px 26.6px 0 0 rgba(0, 231, 255, 0.6),
    -8.68px 26.6px 0 0 rgba(0, 231, 255, 0.8), -22.7px 16.5px 0 0 #00e7ff;
  animation: spinner-b87k6z 1s infinite linear;
}

@keyframes spinner-b87k6z {
  to {
    transform: rotate(360deg);
  }
}
::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: black;
  border-radius: 18px;
}

::-webkit-scrollbar
{
	width: 6px;
  border-radius: 18px;

	background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb
{
  border-radius: 18px;
	background-color: #F5F5F5;
}


.sticky-header {
  @apply sticky top-0 z-10 bg-white;
}

.sticky-header {
  position: -webkit-sticky; /* For Safari */
  position: sticky;
  top: 0;
  z-index: 10;
  background-color: white; /* or any background color that matches your design */
}

.table-container {
  max-height: 800px; /* Adjust as needed */
  overflow-y: auto;
}

.table-auto{
  table-layout: auto;
}
	
.table-fixed{
  table-layout: fixed;
}	